import Menu from "./Menu";
import PageContent from "./PageContent";

export default class App {
  constructor() {
    // this.menu = new Menu();
    // this.menu.addEventListener("click", this.onClickMenu.bind(this));
    // this.mainColumn = document.querySelector(".mainColumn");
    // this.pageContent = new PageContent(this.mainColumn, this.menu.homepage_id);
    // this.pageContent.addEventListener("click", this.onClickMenu.bind(this));
    // //
    // window.history.pushState({ id: this.menu.homepage_id }, "", ``);
    // window.onpopstate = (e) => {
    //   console.log(e.state);
    //   if (e.state) {
    //     this.pageContent._id = e.state.id;
    //     this.menu.setTitle(e.state.id);
    //   }
    // };
    this.initApp();
  }

  // async initApp() {
  //   this.menu = new Menu();
  //   await this.menu.initContent();
  //   this.menu.addEventListener("click", this.onClickMenu.bind(this));

  //   // get url parameter
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const id = urlParams.get("id");
  //   if (id) {
  //     window.history.pushState({ id: id }, "", `?id=${id}`);
  //   } else {
  //     window.history.pushState({ id: this.menu.homepage_id }, "", ``);
  //   }

  //   this.mainColumn = document.querySelector(".mainColumn");
  //   this.pageContent = new PageContent(
  //     this.mainColumn,
  //     id ? id : this.menu.homepage_id
  //   );
  //   this.pageContent.addEventListener("click", this.onClickMenu.bind(this));

  //   // set menu title
  //   this.menu.setTitle(id ? id : this.menu.homepage_id);

  //   //
  //   // window.history.pushState({ id: this.menu.homepage_id }, "", ``);
  //   window.onpopstate = (e) => {
  //     console.log(e.state);
  //     if (e.state) {
  //       this.pageContent._id = e.state.id;
  //       this.menu.setTitle(e.state.id);
  //     }
  //   };
  // }
  async initApp() {
    this.menu = new Menu();
    await this.menu.initContent();
    this.menu.addEventListener("click", this.onClickMenu.bind(this));

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    console.log("URL PARAMS: ", urlParams);
    console.log("ID: ", id);
    this.mainColumn = document.querySelector(".mainColumn");
    this.pageContent = new PageContent(
      this.mainColumn,
      id ? id : this.menu.homepage_id
    );
    this.pageContent.addEventListener("click", this.onClickMenu.bind(this));

    this.menu.setTitle(id ? id : this.menu.homepage_id);

    window.onpopstate = (e) => {
      if (e.state && e.state.id) {
        const newStateId = e.state.id;
        this.pageContent._id = newStateId;
        this.menu.setTitle(newStateId);
      }
    };

    if (!id) {
      window.history.pushState({ id: this.menu.homepage_id }, "", "/");
    }
  }

  onClickMenu(id) {
    this.pageContent._id = id;
    this.menu.setTitle(parseInt(id));
    window.history.pushState({ id: parseInt(id) }, "", `?id=${parseInt(id)}`);
  }
}
